
  import Vue, { PropType, VNode } from 'vue';

  const debug = process.env.NODE_ENV !== 'production';

  export default Vue.extend({
    props: {
      id: {
        type: String as PropType<string>,
        required: true,
      },
    },
    render(): VNode | null {
      // Guard against PPM not having a ‘feature flags’ vuex store.
      const featureFlagsLoaded =
        this.$store.getters['features/loaded']?.() ?? false;
      const enabled = featureFlagsLoaded
        ? this.$store.getters['features/featureById'](this.id).enabled
        : debug;
      return enabled ? (this.$scopedSlots['default']?.({})?.[0] ?? null) : null;
    },
  });
